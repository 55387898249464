<template>
  <v-form
    ref="invoiceForm"
    v-model="valid"
    lazy-validation>
    <v-container
      grid-list-xl>
      <v-layout
        fluid
        pt-0
        wrap>
        <v-flex xs12>
          <app-card
            :footer="true"
            heading="Custom invoice information"
            col-classes="xl12 lg12 md12 sm12 xs12"
            custom-classes="mb-0"
          >
            <div>
              <rotate-square2 v-if="loading" />
              <v-container
                v-else
                grid-list-xl>
                <v-layout
                  row
                  wrap>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.number"
                      :rules="[v => Boolean(v) || 'Please provide a number']"
                      readonly
                      type="text"
                      label="Invoice number"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.driver.name"
                      :rules="[v => Boolean(v) || 'Please provide a driver name']"
                      readonly
                      type="text"
                      label="Driver name"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.passenger.name"
                      :rules="[v => Boolean(v) || 'Please provide a customer name']"
                      readonly
                      type="text"
                      label="Customer name"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.vat"
                      readonly
                      type="text"
                      label="VAT"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.totalExclVAT"
                      readonly
                      type="text"
                      label="Total exclude vat"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.totalInclVAT"
                      readonly
                      type="text"
                      label="Total include vat"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.sentAt"
                      readonly
                      type="datetime-local"
                      label="Sent at"
                    />
                  </v-flex>
                  <v-flex
                    sm6>
                    <v-text-field
                      :max-length="TEXT_FIELD_MAX_LENGTH"
                      v-model="invoice.invoicedAt"
                      readonly
                      type="datetime-local"
                      label="Invoiced At"
                    />
                  </v-flex>
                  <v-flex
                    sm12>
                    <h3>Lines:</h3>
                    <div
                      class="pa-3 line"
                      v-for="(line, index) in invoice.lines"
                      :key="index">
                      <v-layout row wrap>
                        <v-flex sm6 class="py-0">
                          <v-text-field
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            v-model="line.name"
                            readonly
                            type="text"
                            label="Name"
                          />
                        </v-flex>
                        <v-flex sm6 class="py-0">
                          <InputHotKeyWrapper>
                            <v-text-field
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="line.price"
                              readonly
                              type="number"
                              label="Price"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex sm6 class="py-0">
                          <InputHotKeyWrapper>
                            <v-text-field
                              :max-length="TEXT_FIELD_MAX_LENGTH"
                              v-model="line.amount"
                              readonly
                              type="number"
                              label="Amount"
                            />
                          </InputHotKeyWrapper>
                        </v-flex>
                        <v-flex sm6 class="py-0">
                          <v-text-field
                            :max-length="TEXT_FIELD_MAX_LENGTH"
                            v-model="line.eoVatCode.description"
                            readonly
                            type="text"
                            label="eoVatCode description"
                          />
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-flex>
                </v-layout>
                <send-invoice-button
                  color="secondary"
                  button-classes='text-white'
                  invoice-type='combined'
                  :invoice-id="invoice.id"
                  :after-action="requestData" />
              </v-container>
            </div>
          </app-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-form>
</template>

<script>
import { TEXT_FIELD_MAX_LENGTH } from '../../../../constants/common'
import CrudTable from '../../../../modules/admin/CrudTable.vue'
import Request from '../../../../helpers/request'
import SendInvoiceButton from 'Components/Buttons/SendInvoiceButton.vue'

export default {
  components: { CrudTable, SendInvoiceButton },
  mixins: [Request],
  data () {
    return {
      editInvoicedAtLoader: false,
      valid: true,
      loading: true,
      data: [],
      invoice: {},
      TEXT_FIELD_MAX_LENGTH,
      editDialog: {
        display: false,
        saveFunction: () => {},
        data: {}
      }
    }
  },
  beforeMount () {
    this.requestData()
  },
  methods: {
    requestData () {
      this.request('GET', `/custom-invoices/${this.$route.params.id}`, {}, ({ data }) => {
        this.invoice = {
          ...data,
          startLocation: data.startLocation || {},
          endLocation: data.endLocation || {},
          declaredDuration: data.declaredDuration || {},
          declaredWaitingTime: data.declaredWaitingTime || {}
        }
      }, (loader) => { this.loading = loader })
    },
    saveFunction () {
      if (this.$refs.invoiceForm.validate()) {
        let body = {
          data: {
            'title': this.invoice.title,
            'description': this.invoice.description,
            'icon': this.invoice.icon
          }
        }

        this.request('PATCH', `/custom-invoices/${this.invoice.id}`, body, ({ data }) => {
          this.$notify({
            group: 'topRight',
            type: 'success',
            text: 'Invoice has been changed'
          })

          this.$router.push('/tables/custom-invoices')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.image-preview {
    img {
      width: 124px;
      height: 124px;
      background-color: white;
      border: 1px solid #DDD;
      padding: 5px;
    }
}

.line {
  border-bottom: 2px solid black;
}

.line:last-child {
  border: none;
}
</style>
